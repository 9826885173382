exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apis-js": () => import("./../../../src/pages/apis.js" /* webpackChunkName: "component---src-pages-apis-js" */),
  "component---src-pages-berufsbildende-js": () => import("./../../../src/pages/berufsbildende.js" /* webpackChunkName: "component---src-pages-berufsbildende-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-provider-js": () => import("./../../../src/pages/courseProvider.js" /* webpackChunkName: "component---src-pages-course-provider-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lehrpersonen-js": () => import("./../../../src/pages/lehrpersonen.js" /* webpackChunkName: "component---src-pages-lehrpersonen-js" */),
  "component---src-pages-market-prices-js": () => import("./../../../src/pages/MarketPrices.js" /* webpackChunkName: "component---src-pages-market-prices-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publisher-js": () => import("./../../../src/pages/publisher.js" /* webpackChunkName: "component---src-pages-publisher-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

